<template>
  <div class="order-page">
    <b-container>
      <b-row>
        <b-col cols="12" lg="4" md="12" sm="12">
          <LoggedInCheckoutDetails v-if="isLoggedIn == true" />
          <NotLoggedInCheckoutDetails v-else />
        </b-col>
        <b-col cols="12" lg="4" md="12" sm="12">
          <OrderShippingComponent
            :isShippingInvalid="form.shippingInvalid"
            :isPaymentInvalid="form.paymentInvalid"
          />
        </b-col>
        <b-col cols="12" lg="3" md="12" sm="12" class="last-col">
          <OrderOverviewComponent
            @invalidateShipping="form.shippingInvalid = true"
            @invalidatePayment="form.paymentInvalid = true"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import OrderOverviewComponent from "@/esf_utrecht_bankxl/core/components/order/OrderOverviewComponent";
import LoggedInCheckoutDetails from "@/esf_utrecht_bankxl/core/components/order/LoggedInCheckoutDetails";
import NotLoggedInCheckoutDetails from "@/esf_utrecht_bankxl/core/components/order/NotLoggedInCheckoutDetails";
import OrderShippingComponent from "@/esf_utrecht_bankxl/core/components/order/OrderShippingComponent";
export default {
  name: "OrderComponent",
  components: {
    NotLoggedInCheckoutDetails,
    OrderShippingComponent,
    OrderOverviewComponent,
    LoggedInCheckoutDetails,
  },
  data() {
    return {
      form: {
        shippingInvalid: false,
        paymentInvalid: false,
      },
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    shippingMethod() {
      return this.$store.getters["cart/getShippingMethod"];
    },
    paymentMethod() {
      return this.$store.getters["cart/getPaymentMethod"];
    },
  },
  watch: {
    shippingMethod(newValue) {
      if (newValue !== null) this.form.shippingInvalid = false;
    },
    paymentMethod(newValue) {
      if (newValue !== null) this.form.paymentInvalid = false;
    },
  },
};
</script>
