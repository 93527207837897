<template>
  <div class="category-filter">
    <div class="desktop-show category-right-top-list-header">
      <p class="category-list-left">
        {{ productsCount }} {{ $t("result") }} | &nbsp;
        {{ $t("sort_by") }}
        <span class="count-icon">
          <select
            id="collection"
            class="filter-list bold"
            :value="sorting"
            @change="$emit('change-sorting', $event)"
          >
            <option
              v-for="option in selectOptions"
              v-bind:key="option.value"
              v-bind:value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <!-- <span class="filter-arrow-icon"
            ><font-awesome-icon icon="chevron-down"
          /></span> -->
        </span>
      </p>
      <p class="category-list-right">
        <span class=""> {{ categoryCollection2 }}</span> &nbsp;
        <span class="count-icon">
          <select
            id="collection2"
            class="filter-list bold"
            @change="$emit('change-per-page', $event)"
            :value="prodsPerPage"
          >
            <option value="12">12</option>
            <option value="20">20</option>
            <option value="24">24</option>
            <option value="36">36</option>
          </select>
          <!-- <span class="filter-arrow-icon"
            ><font-awesome-icon icon="chevron-down"
          /></span> -->
        </span>
      </p>
    </div>
    <div class="tab-mob-show">
      <div
        class="pb-2 pt-2 filter-result text-center text-white bg-primary"
        @click="openSearchSidebar"
      >
        <span class="filter-icon"><font-awesome-icon icon="filter" /></span>
        <span class="filter-txt pl-2"
          >{{ $t("search_help") }} {{ productsCount }} {{ $t("result") }}</span
        >
      </div>
      <div class="pt-20 filter-box category-right-top-list-header">
        <p class="category-list-left d-flex align-items-center">
          <span class="filter-txt-span">
            {{ $t("result") }} {{ productsCount }} </span
          >&nbsp;
          <span class="">
            <select
              id="collection3"
              class="filter-list-mob bold"
              :value="sorting"
              @change="$emit('change-sorting', $event)"
            >
              <option
                v-for="option in selectOptions"
                v-bind:key="option.value"
                v-bind:value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </span>
        </p>
        <p class="category-list-righ d-flex align-items-center">
          <span class="filter-txt-span"> {{ categoryCollection2 }}</span
          >&nbsp;
          <span>
            <select
              id="collection2"
              class="filter-list-mob"
              @change="$emit('change-per-page', $event)"
              :value="prodsPerPage"
            >
              <option value="12">12</option>
              <option value="20">20</option>
              <option value="24">24</option>
              <option value="36">36</option>
            </select>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SearchPageContentFilter",
  inject: ["openSearchSidebar"],
  emits: ["change-sorting", "change-per-page"],
  props: {
    productsCount: Number,
    sorting: String,
    selectOptions: Array,
    prodsPerPage: Number,
  },
  mounted() {},
  data() {
    return {
      categoryCollection2: this.$t("number_of_results_per_page"),
    };
  },
};
</script>