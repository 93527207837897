<template>
  <b-card-body
    class="shopping-product-slider"
    v-if="relatedProduct != null"
    v-match-heights="{ el: ['.price'] }"
  >
    <div class="wrap">
      <div class="product-img">
        <router-link
          :to="`/${relatedProduct.url_key}/`"
          @click.native="$root.$emit('closeSearchModal')"
        >
          <b-img
            :alt="relatedProduct.name.slice(0, 39)"
            :src="
              relatedProduct.thumbnail.url
                ? relatedProduct.thumbnail.url
                : relatedProduct.thumbnail.medium
            "
          />
        </router-link>
      </div>
      <div class="txt-div">
        <p
          class="offer-tag"
          :class="{
            'opacity-0':
              relatedProduct.price_range.maximum_price.discount.percent_off ==
              0,
          }"
        >
          <b-link class="offer-link">
            -&nbsp;{{
              relatedProduct.price_range.maximum_price.discount.percent_off.toFixed(
                0
              )
            }}%
          </b-link>
        </p>
        <div class="shopping-cart-icon">
          <span class="right">
            <b-link href="#" @click="addToCart()">
              <font-awesome-icon
                icon="shopping-cart"
                style="color: #fff; font-size: 18px"
              />
            </b-link>
          </span>
        </div>
        <p class="price">
          <span
            v-if="
              relatedProduct.price_range.minimum_price.regular_price.value !=
              relatedProduct.price_range.minimum_price.final_price.value
            "
            class="old"
            ><s>
              {{
                formatCurrency(
                  relatedProduct.price_range.minimum_price.regular_price.value
                )
              }}</s
            ></span
          >
          <span class="new">
            <strong>
              {{
                formatCurrency(
                  relatedProduct.price_range.minimum_price.final_price.value
                )
              }}
            </strong>
            {{ relatedProduct.unit }}</span
          >
        </p>
        <div class="extra">
          <div class="left">
            <b-card-text class="productInfo">
              <router-link
                :to="`/${relatedProduct.url_key}/`"
                @click.native="$root.$emit('closeSearchModal')"
              >
                {{ relatedProduct.name }}
              </router-link>
            </b-card-text>
          </div>
          <div class="shopping-cart-wrap">
            <b-link
              v-if="relatedProduct.DeliveryTime"
              class="delivery-msg"
              :style="`color: ${relatedProduct.DeliveryTime.color}`"
            >
              {{ relatedProduct.DeliveryTime.short }}
            </b-link>
          </div>
        </div>
      </div>
    </div>
  </b-card-body>
</template>
<script>
import cartLogoWhite from "@/esf_utrecht_bankxl/assets/images/shopping-cart-white.png";
import shoppingImg from "@/esf_utrecht_bankxl/assets/images/s-prd-img.jpg";
export default {
  name: "ShoppingProductCardComponent",
  props: ["relatedProduct"],
  data() {
    return {
      cartLogoWhite,
      shoppingImg,
    };
  },

  methods: {
    async addToCart() {
      if (this.relatedProduct.__typename == "SimpleProduct") {
        const item =
          '{data: {sku: "' +
          this.relatedProduct.sku +
          '", quantity:' +
          1 +
          "}}  ";
        let retval = await this.$store.dispatch("cart/addToCart", {
          type: this.relatedProduct.__typename,
          item: item,
        });

        if (retval == false) {
          const msg = {
            type: "danger",
            title: this.$t("shopping_basket"),
            text: this.$t("not_add_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        } else {
          const msg = {
            type: "success",
            title: this.$t("shopping_basket"),
            text: this.$t("added_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        }
      } else {
        this.$router.push(this.relatedProduct.url_key + "/");
      }
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount);
    },
  },
};
</script>
